import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.withCredentials = true;
function errorsOrDefault(error, defaultErrorMessage = 'Request failed for an unknown reason') {
  return error && error.response && error.response.data
    ? { message: error.response.data, code: error.response.status }
    : [defaultErrorMessage];
}

let token = Cookies.get('th-userinfo');

const request = async function(req, defaultMsg) {
  if (token) {
    req.headers = { ...req.headers, Authorization: `Bearer ${token}` };
  }
  try {
    const response = await axios(req);
    // console.log(response.headers);
    return response;
  } catch (e) {
    return {
      error: errorsOrDefault(e, defaultMsg),
      status: e && e.response && e.response.status ? e.response.status : null,
    };
  }
};

// const dbUrl = 'http://localhost:5000/';
// const dbUrl = 'https://pasulj.targethype.com/';
// const dbUrl = 'http://targethypebs-env.u6zsmkxkda.eu-central-1.elasticbeanstalk.com/';
const dbUrl = 'https://littlejack.targethype.com/';
class Request {
  static getAuth() {
    return token;
  }

  static setAuth(newToken) {
    token = newToken;
  }

  static requestMapping = {
    professionalInfo: dbUrl + 'candidate-professional',
    personalInfo: dbUrl + 'candidate-personal',
    companyInfo: dbUrl + 'company-data',
    register: dbUrl + 'register',
    login: dbUrl + 'login',
    search: dbUrl + 'targethype/admin/candidate/search',

    candidate: dbUrl + 'targethype/admin/candidate',
    setBonusPaid: dbUrl + 'targethype/admin/candidate/bonus',

    util: {
      states: dbUrl + 'util/state',
      countries: dbUrl + 'util/country',
      industries: dbUrl + 'util/industry',
      functions: dbUrl + 'util/function',
      companies: dbUrl + 'util/company',
      jobTitles: dbUrl + 'util/job-title',
    },
  };

  static get({ params = {}, url, headers = {}, responseType, defaultMsg } = {}) {
    return request(
      {
        method: 'GET',
        url,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static post({ data = {}, params = {}, url, headers = {}, responseType, defaultMsg } = {}) {
    return request(
      {
        method: 'POST',
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static put({ data = {}, params = {}, url, headers = {}, responseType, defaultMsg } = {}) {
    return request(
      {
        method: 'PUT',
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static patch({ data = {}, params = {}, url, headers = {}, responseType, defaultMsg } = {}) {
    return request(
      {
        method: 'PATCH',
        url,
        data,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }

  static delete({ params = {}, url, headers = {}, responseType, defaultMsg } = {}) {
    return request(
      {
        method: 'DELETE',
        url,
        params,
        headers,
        responseType,
      },
      defaultMsg
    );
  }
}

export default Request;
